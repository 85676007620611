<template>
  <q-layout view="hhh lpr lfr">

    <q-header class="navbar">
      <q-toolbar class="bg-verde">
        <q-btn flat class="q-px-xs" color="white" icon="menu" @click="onDrawer" />
        <q-toolbar-title class="text-white text-center text-bold text-h4">
          {{ showTitle }}
        </q-toolbar-title>
        <div
          v-show="isAdmin"
        >
          <q-btn
            flat
            class="q-px-xs"
            color="white"
            icon="fas fa-user-shield"
            @click="onAdmin"
          />
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      class="bg-verde"
      v-model="drawer"
      show-if-above
      mini-to-overlay
      :width="270"
      :breakpoint="100"
      :mini="miniState"
      @mouseenter="miniState = false"
      @mouseleave="miniState = true"
    >
      <div class="spaceLogo row text-center justify-center">
        <div v-if="!miniState">
          <div class="logo full-width">
            <q-avatar v-if="!isMobile" size="155px" style="background-color: #f6f6f6;">
              <img
                :src="logo"
                fit="fill"
              />
            </q-avatar>
            <q-avatar v-else size="100px" style="background-color: #f6f6f6;">
              <img
                :src="logo"
                fit="fill"
              />
            </q-avatar>
          </div>
          <div class="enterprise full-width text-h6 text-bold text-white">
            {{ this.nameCompany }}
            <q-separator color="white" class="q-my-md" />
          </div>
        </div>  
      </div>
      <div class="column flex justify-center">
        <q-list padding>
          <q-item
            clickable
            v-ripple
            class="text-white q-py-sm"
            v-for="(menu, index) in menus"
            :key="index"
            @click="drawClick(menu.name)"
          >
            <q-item-section avatar>
                <q-icon :name="menu.icon">
                  <q-badge
                    v-if="menu.qtdBadge"
                    rounded
                    color="red-9"
                    class="text-white text-bold"
                    floating
                  >
                    {{ qtdPreSale }}
                  </q-badge>
                </q-icon>
            </q-item-section>
            <q-item-section side class="text-white captalize">
              {{ menu.label }}
            </q-item-section>
          </q-item>
        </q-list>
      </div>  
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { supabase } from '../supabase'
import Slug from '../helpers/Slug'

export default {
  data() {
    return {
      menus: [
        {icon: "home", name: "home", label: "Painel Gerencial", route: "/home", nReduz: 'Gerencial', qtdBadge: false},
        // {icon: "fas fa-cart-plus", name: "preSales", label: "Orçamentos", route: "/preSales", nReduz: 'Orçamentos', qtdBadge: true},
        {icon: "shopping_basket", name: "sells", label: "Venda de Produtos", route: "/sells", nReduz: 'Vendas', qtdBadge: false},
        {icon: "build_circle", name: "repair", label: "Venda de Serviços", route: "/repair", nReduz: 'OS`s', qtdBadge: false},
        {icon: "list_alt", name: "orders", label: "Encomendas", route: "/orders", nReduz: 'Pedidos', qtdBadge: false},
        {icon: "groups", name: "nets", label: "Redes Sociais", route: "/nets", nReduz: 'Sociais', qtdBadge: false},
        {icon: "category", name: "warehouse", label: "Controle de Estoque", route: "/warehouse", nReduz: 'Estoque', qtdBadge: false},
        {icon: "assignment_ind", name: "clients", label: "Cadastro de Clientes", route: "/clients", nReduz: 'Clientes', qtdBadge: false},
        {icon: "handyman", name: "services", label: "Cadastro de Serviços", route: "/services", nReduz: 'Serviços', qtdBadge: false},
        {icon: "receipt_long", name: "expenses", label: "Cadastro de Despesas", route: "/expenses", nReduz: 'Despesas', qtdBadge: false},
        {icon: "monetization_on", name: "cashflow", label: "Fluxo de Caixa", route: "/cashflow", nReduz: 'Fluxo Caixa', qtdBadge: false},
        // {icon: "far fa-image", name: "galery", label: "Galeria de Imagens", route: "/galery", nReduz: 'Galeria', qtdBadge: false},
        {icon: "settings", name: "settings", label: "Configurações", route: "/settings", nReduz: 'Configuração', qtdBadge: false},
        {icon: "logout", name: "logout", label: "Sair", route: "/", nReduz: 'Sair', qtdBadge: false},
      ],
      miniState: true,
      drawer: false,
      qtdPreSale: 0,
      logo: "/geoffice_logo_padrao.png",
      nameCompany: null,
      isAdmin: false,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
    // async isAdmin() { //~> CRIADO A FUNÇÃO NO BEFORE CREATED

    //   const { data } = await supabase.auth.getUser()
    //   const email = data.user.email
    //   // const sp = localStorage.getItem('sb-bxtrcipjvgupnswaihjg-auth-token')
    //   // const email = sp.user.email

    //   console.log(data);
    //   console.log(email);

    //   if(
    //     email == 'wesleyribeiro123@gmail.com' ||
    //     email == 'gestaoexcel.oficial@gmail.com'
    //   ) {
    //     return true;
    //   }

    //   return false;
    // },
    showTitle() {
      let name;
      let route = this.$route.path;

      this.menus.forEach(menu => {
        if(menu.route === route) {
          if(this.isMobile) {
            name = menu.nReduz;
          } else {
            name = menu.label;
          }
        } else if (route === '/panelADM') {
          if(this.isMobile) {
            name = 'ADMIN'
          } else {
            name = 'ADMINISTRATIVO'
          }
        }
      });

      return name;
    },
  },
  methods: {
    drawClick(menu) {
      this.menus.forEach(async i => {
        if( i.name === menu ) {
          if( i.route !== this.$router.path ) {

            if(i.name === 'logout') {
              await supabase.auth.signOut();
              localStorage.removeItem('defaultColor');
              localStorage.removeItem('enterprise');
              localStorage.removeItem('recnoClient');
            }

            this.miniState = false;
            this.drawer = true;
            this.$router.push(i.route);
          }
        }
      });
    },
    onDrawer() {
      if(this.drawer === true) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    onAdmin() {
      this.$router.push('/panelADM')
    }
  },
  async beforeCreate() {
    const enterprise = localStorage.getItem('enterprise')
    const slug = Slug(enterprise)

    const { count } = await supabase
      .from('GE_PRE_SALE')
      .select('RECNO', { count: 'exact' })
      .filter('GE_ENTERPRISE', 'eq', slug)
      .eq('GE_STATUS', 'PENDENTE')

    this.qtdPreSale = count;

    const { data } = await supabase
      .from('GE_PERFIL_USERS')
      .select('*')
      .filter('GE_ENTERPRISE', 'eq', enterprise)

    console.log(data[0].GE_LOGO)

    const company = String(data[0]?.GE_NAMECOMPANY).length > 1 ? data[0]?.GE_NAMECOMPANY : enterprise;
    this.nameCompany = company;

    if(!String(localStorage.getItem('logo')).includes('/assets/')) {
      this.logo = localStorage.getItem('logo')
    }

    const { data: dataAuth } = await supabase.auth.getUser()
    const email = dataAuth.user.email

    if(
      email == 'wesleyribeiro123@gmail.com'
        ||
      email == 'geoffice.oficial@gmail.com'
        ||
      email == 'amanda_soares_cf2@hotmail.com'
        ||
      email == 'larissa.loraynne.35@gmail.com.br'
    ) {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  }
}
</script>

<style lang="scss">
  /* Celulares (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px) {
    .spaceLogo {
      height: 165px;
    }

    .enterprise {
      margin-top: 10px;
    }
  }

  /* Tablets (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .spaceLogo {
      height: 200px;
    }

    .enterprise {
      margin-top: 15px;
    }
  }

  /* Desktop (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .spaceLogo {
      height: 230px;
    }

    .enterprise {
      margin-top: 20px;
    }
  }
</style>

