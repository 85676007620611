import Dialog from 'quasar/src/plugins/Dialog.js';;
import { supabase } from '../../../supabase'
// const enterprise = localStorage.getItem('enterprise')

export async function getTOPVendas(_, infos) {
  try {
    let { data, error, status } = await supabase
    .rpc('top3_vendas', {
      enterprise: infos.enterprise, 
      league: infos.category, 
      date_start: infos.date_start,
      date_end: infos.date_end, 
    })

    if (error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.')
    }
  } catch (err) {
    Dialog.create({
      title: 'GE Office',
      message: err.message || err.error_description
    });
  }
}

export async function getDespesas(_, infos) {
  try {
    let { data, error, status } = await supabase
    .rpc('total_despesas', {
      enterprise: infos.enterprise, 
      date_start: infos.date_start,
      date_end: infos.date_end, 
    })

    if (error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.')
    }
  } catch (err) {
    Dialog.create({
      title: 'GE Office',
      message: err.message || err.error_description
    });
  }
}

export async function getReceitas(_, infos) {
  try {
    let { data, error, status } = await supabase
    .rpc('total_vendas', {
      enterprise: infos.enterprise,
      league: infos.league,
      date_start: infos.date_start,
      date_end: infos.date_end, 
    })

    if (error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.')
    }
  } catch (err) {
    Dialog.create({
      title: 'GE Office',
      message: err.message || err.error_description
    });
  }
}

export async function rpc(_, infos) {
  try {
    let { data, error, status } = await supabase
      .rpc(infos.function, {
        enterprise: infos.enterprise,
        league: infos.league,
        date_start: infos.date_start,
        date_end: infos.date_end,
    });

    if(error && status !== 406) throw error;

    if(data) {
      return data;
    } else {
      throw new Error('Não foi possível encontrar dados.');
    }
  } catch (e) {
    Dialog.create({
      title: 'GE Office',
      message: e.message || e.error_description
    });
  }
}