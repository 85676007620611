import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  //~~> ROTAS GE OFFICE
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home')
  },
  {
    path: '/sells',
    name: 'Vendas',
    component: () => import('../views/Sells')
  },
  {
    path: '/orders',
    name: 'Pedidos',
    component: () => import('../views/Orders')
  },
  {
    path: '/repair',
    name: 'Ordem de Serviço',
    component: () => import('../views/Repair')
  },
  {
    path: '/cashflow',
    name: 'Fluxo de Caixa',
    component: () => import('../views/CashFlow')
  },
  {
    path: '/nets',
    name: 'Redes Sociais',
    component: () => import('../views/NetSocial')
  },
  {
    path: '/warehouse',
    name: 'Cadastro de Estoque',
    component: () => import('../views/Warehouse')
  },
  {
    path: '/clients',
    name: 'Cadastro de Clientes',
    component: () => import('../views/Clients')
  },
  {
    path: '/expenses',
    name: 'Cadastro de Despesas',
    component: () => import('../views/Expenses')
  },
  {
    path: '/services',
    name: 'Cadastro de Serviços',
    component: () => import('../views/Services')
  },
  {
    path: '/preSales',
    name: 'Orçamentos',
    component: () => import('../views/PreSale')
  },
  {
    path: '/galery',
    name: 'Galeria de Imagens',
    component: () => import('../views/Galery')
  },
  {
    path: '/settings',
    name: 'Configurações',
    component: () => import('../views/Settings')
  },
  {
    path: '/panelADM',
    name: 'Painel Administrativo',
    component: () => import('../views/PanelADM')
  },
  {
    path: '/catalog/:slug',
    name: 'Catálogo',
    component: () => import('../views/Catalog')
  },
  {
    path: '/comprovante',
    name: 'Emissor de Comprovante',
    children: {
      sistema: false,
    },
    component: () => import('../views/Micelaneas/emissorNotinha')
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// console.log(router);
// router.beforeEach((to, from, next) => {
// 
// })

export default router
